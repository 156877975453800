import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './slider.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import home_it from "./translations/it/home.json";
import home_es from "./translations/es/home.json";
import home_en from "./translations/en/home.json";
import {Context} from "./context";

const root = ReactDOM.createRoot(document.getElementById('root'));

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'it',
  resources: {
    it: {home: home_it},
    en: {home: home_en},
    es: {home: home_es},
  },
}).then(r => i18next);
root.render(<I18nextProvider i18n={i18next}><Context><App/></Context></I18nextProvider>);

//serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
