import {AuthAPI} from "../api/AuthAPI";

export function getFileUrl(data, key, imageFallBack = null) {
    let url;
    try {
        if (key.toString() in data) {
            url = JSON.parse(data[key.toString()]);
            if (url.length === 2) {
                url = ((!process.env.NODE_ENV || process.env.NODE_ENV === 'developmentf') ? 'http://0.0.0.0' : 'https://www.gloowe.com') + url[1];
            }
        }
    } catch (e) {
        url = imageFallBack;
    }
    return url;
}

export async function addAnalytics(store_id, ref_id, type) {
    if (store_id !== null) {
        let dataAnalytics = {store_id: store_id, ref_id: ref_id};
        await AuthAPI.analytics(type, dataAnalytics);
    }
}