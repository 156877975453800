import './index.css';
import React from "react";
import {motion} from "framer-motion";
import Backdrop from "../backdrop/index";
import {SVG_ICON_CLOSE} from "../../utils/AppSvg";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

const Modal = ({handleClose, dimension = 'modal-normal', children, modalCss = ''}) => {
    return (
        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => e.stopPropagation()}  // Prevent click from closing modal
                className={"position-relative modal " + dimension + " bg-brand-white"}
                variants={dropIn} initial="hidden" animate="visible" exit="exit">
                { handleClose && <button type="button" className={"position-absolute px-btn close"} onClick={handleClose}><SVG_ICON_CLOSE/></button>}
                <div className={"modal-container " + modalCss}>{children}</div>
            </motion.div>
        </Backdrop>
    );
};
export default Modal