import './index.css';
import React, {useEffect, useRef, useState} from 'react';
import {SVG_SHOPPING_CART} from "../../utils/AppSvg";
import {useGlobalContext} from "../../context";

const Settings = () => {
    const context = useGlobalContext();
    if (context.dataStoreSettings.store_enabled_orders === true) {
        return (
            <div className={"settings"}>
                <ul className="navbar-nav-icons ms-auto flex-row align-items-center navbar-nav">
                    <li className="d-block nav-item">
                        <div className="px-0 notification-indicator notification-indicator-warning position-relative notification-indicator-fill nav-link"
                             onClick={() => context.setShowBasket(!context.showBasket)}>
                            <span className="notification-indicator-number">{context.dataBasket.total_qta}</span>
                            <SVG_SHOPPING_CART/>
                        </div>
                    </li>
                </ul>
            </div>
        );
    } else return (<></>);
}

const Navigation = ({store, listMenu}) => {
    let navRef = useRef();
    let year = new Date().getFullYear();
    const context = useGlobalContext();
    const [renderListMenu, setRenderListMenu] = useState(<></>);
    const [renderAvatar, setRenderAvatar] = useState(<></>);

    useEffect(() => {
        setRenderListMenu(
            <div className={"container-fluid"}>
                <div className="justify-content-between nav nav-tabs h-100">
                    {
                        listMenu && listMenu.map((item, index) =>
                            <div key={index.toString()}
                                 className={"nav-item text-center w-100 cursor-pointer"}
                                 onClick={() => {
                                     context.setCurrentMenu(item.id);
                                     navRef.current.classList.remove('show');
                                 }}>
                                <p className={"nav-link px-3 pb-3 m-0 " + (context.currentMenuId === item.id ? ' active' : '')}>{item.name}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        );

        let store_logo = null;
        if (store.url_menu_cover !== null) {
            store_logo = JSON.parse(store.url_store_logo);
            if (store_logo.length === 2) store_logo = 'https://www.gloowe.com' + store_logo[1];
        }

        setRenderAvatar(
            <div className="avatar avatar-2xl fs-2 cursor-pointer"
                 onClick={() => {
                     context.setCurrentMenu(0);
                     context.setShowBasket(false);
                     navRef.current.classList.remove('show');
                 }}>
                {
                    store && store_logo !== null
                        ? <img src={store_logo} alt="Logo menù" width="88" className={"rounded-1"}/>
                        : <span className={"rounded-1"}>{store ? (store.business_name.charAt(0) ?? '') : ''}</span>
                }
            </div>
        );
    }, [context.currentMenuId]);

    function toggleMenu() {
        navRef.current.classList.toggle('show');
    }

    return (
        <>
            <header className="w-100 position-relative bg-white pt-2">
                <div className="container">
                    <div className="row">
                        <div className="col-12 position-relative">
                            <div className={"d-flex align-items-center justify-content-between"}>
                                <div aria-label="toggle gloowe"
                                     className="toggle-icon-wrapper text-center d-sm-none navbar-toggler spin">
                                    <button onClick={toggleMenu}
                                            className="m-auto navbar-toggler-humburger-icon btn btn-link d-flex flex-center">
                                        <span className="navbar-toggle-icon"><span
                                            className="toggle-line"></span></span>
                                    </button>
                                </div>
                                {renderAvatar}
                                <div className={"d-none d-sm-block"}>{renderListMenu}</div>
                                <Settings/>
                            </div>
                        </div>
                        <nav ref={navRef} className={"col-12 container-header d-block d-sm-none"}>
                            <section className="bg-primary mt-2 mb-3 content-avatar">
                                <button onClick={toggleMenu} className="btn btn-link position-absolute px-2 text-white"
                                        style={{top: 0, left: '5px'}}>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark"
                                         className="svg-inline--fa fa-xmark icon" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="currentColor"
                                              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                                    </svg>
                                </button>
                                {renderAvatar}
                            </section>
                            {renderListMenu}
                            <section className="col-12">
                                <div className="text-center">
                                    <span className="text-muted fs--2">Wine Cavour © {year}</span>
                                    <p className="text-muted fs--2">Ideato e creato da <a
                                        href={"https://manuelbraghin.com"} className={"no-link"} target={"_blank"}
                                        rel="noreferrer">Manuel Braghin</a> & <a href={"https://business.gloowe.com"}
                                                                                 className={"no-link"} target={"_blank"}
                                                                                 rel="noreferrer">gloowe srl</a></p>
                                </div>
                            </section>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navigation;