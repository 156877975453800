import React from 'react';

const Footer = () => {
    let year = new Date().getFullYear();
    return (
        <footer id="footer" className="container bg-transparent">
            <div className="footer-second-row">
                <span className="text-muted fs--2">Wine Cavour © {year}</span>
                <p className="text-muted fs--2">Ideato e creato da <a href={"https://manuelbraghin.com"} className={"no-link"} target={"_blank"} rel="noreferrer">Manuel Braghin</a> & <a href={"https://business.gloowe.com"} className={"no-link"} target={"_blank"} rel="noreferrer">gloowe srl</a></p>
            </div>
        </footer>
    );

}

export default Footer;