import "./index.css";
import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import IconMenu from "../../assets/images/icon-menu.png";
import {Content} from "./content";
import {useGlobalContext} from "../../context";
import {addAnalytics, getFileUrl} from "../../utils/Utils";
import {AuthAPI} from "../../api/AuthAPI";
import LoadingPage from "../loadingpage";

const Accordion = ({i, item, expanded, setExpanded}) => {
    const isOpen = i === expanded;

    async function onClickCategory(e, store_id, ref_id) {
        e.preventDefault();
        await addAnalytics(store_id, ref_id, 'menu-category-visit');
        setExpanded(isOpen ? false : i)
    }

    return (
        <>
            <motion.header initial={false} animate={{backgroundColor: isOpen ? "#160c00" : "#927e67"}}
                           onClick={(e) => onClickCategory(e, item.store_id, item.id)}
                           className={"text-uppercase"}><p className={"m-0 fw-bold"}>{item.name}</p></motion.header>
            <AnimatePresence initial={false}>
                {
                    isOpen && (
                        <motion.section key="content" initial="collapsed" animate="open" exit="collapsed"
                                        variants={{open: {opacity: 1, height:'auto'}, collapsed: {opacity: 0, height:'0px'}}}
                                        transition={{duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98]}}>
                            <Content description={item.description ?? null} data_consumable={item.list_consumable}/>
                        </motion.section>
                    )
                }
            </AnimatePresence>
        </>
    );
};

const Menu = ({currentMenuId}) => {
    const context = useGlobalContext();
    const [expanded, setExpanded] = useState(false); //<false | counter(currentData)>(0);
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [listAllergen, setListAllergen] = useState({});
    const [data, setData] = useState({
        current_menu: '',
        current_image_menu: '',
    });

    useEffect(() => {
        async function fetchData() {
            let data = {token_menu: context.TOKEN_MENU_DOMAIN, action: 'GET_ITEMS_BY_MENU', menu_id: currentMenuId};
            let responseData = await AuthAPI.show(data);
            if (responseData.status === 200) {
                if (responseData.data.status === true) {
                    let image_menu_cover = getFileUrl(responseData.data.context.current_menu, 'url_menu_cover', IconMenu);
                    setData({
                        ...data
                        , current_menu: responseData.data.context.current_menu ?? ''
                        , current_image_menu: image_menu_cover
                    });

                    setListAllergen(responseData.data.context.list_allergen ?? null);

                    // Add Analytics
                    let currentStore = responseData.data.context.store ?? null;
                    let currentStoreId = currentStore !== null ? currentStore.id : null;
                    await addAnalytics(currentStoreId, currentMenuId, 'menu-visit');
                }
                return responseData.data.status;
            } else return false;
        }

        setIsDataComplete(false);
        fetchData().then((response) => {
            setIsDataComplete(response);
        });
    }, [currentMenuId]);

    if (!isDataComplete) return <LoadingPage/>;

    return (
        <div className={"row"}>
            <div className={"col-12 p-0 d-block d-sm-none"}>
                <div className={"bg-primary px-3 py-2"}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <div className={"text-white"} onClick={() => {context.setCurrentMenu(0);}}>
                                    <label className={"m-0 p-0 fw-semi-bold"}>Home</label>
                                </div>
                            </li>
                            <li className="breadcrumb-item active text-white" aria-current="page">
                                <label className={"m-0 p-0 fw-semi-bold"}>{data.current_menu.layout}</label>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className={"col-12 mt-3"}>
                <div className={"position-relative"}>
                    <img src={data.current_image_menu} className="img-fluid me-3 d-block w-100 fit-cover"
                         alt={currentMenuId} style={{background: '#f8f8f8', borderRadius: '10px'}}/>
                </div>
            </div>
            <div className={"col-12 category-content"}>
                {
                    data.current_menu.list_category.map((item, index) => (
                        <Accordion key={index.toString()} item={item} i={index} expanded={expanded} setExpanded={setExpanded}/>
                    ))
                }
            </div>
        </div>
    )
}

export default Menu;
